import * as React from "react"
import { Link } from "gatsby"
import Layout from "../layout/default"
import { Helmet } from "react-helmet"

const ImprintPage = () => {
  return (
    <Layout>
        <Helmet title="Imprint - Chunk Studios"></Helmet>
        <div style={{width: "100%"}}>
            <h1>Imprint</h1>
            
<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
<p>Taisen Schettler<br />
Am Wöddel 4<br />
24558 Henstedt-Ulzburg</p>

<h2>Kontakt</h2>
<p>Telefon: +49 (0) 17643834405<br />
E-Mail: admin@commandblock.com</p>

<h2>Umsatzsteuer-ID</h2>
<p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
DE329093589</p>

<p>Es gelten folgende berufsrechtliche Regelungen:</p>
<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

<p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
            
        </div>
    </Layout>
  )
}

export default ImprintPage
